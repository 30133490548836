
import Vue from "vue"
import NavHelpCentre from "./NavHelpCentre.vue"
import NavOurServices from "./NavOurServices.vue"
import { getScreenWidth } from "~/scripts/useful-functions"

export default Vue.extend({
  name: "TheRebrandHeader",
  data() {
    return {
      showLoginForm: false,
    }
  },
  methods: {
    toggleOurServices(override: null | boolean = null) {
      const ourServicesMenu = this.$refs.ourServicesMenu as InstanceType<typeof NavOurServices>
      if (ourServicesMenu) {
        ourServicesMenu.toggleMenu(override)
      }
    },
    toggleHelpCentre(override: null | boolean = null) {
      const helpCentreMenu = this.$refs.helpCentreMenu as InstanceType<typeof NavHelpCentre>
      if (helpCentreMenu) {
        helpCentreMenu.toggleMenu(override)
      }
    },
    hideOurServicesDesktop() {
      const screenWidth = getScreenWidth()
      if (screenWidth && screenWidth >= 768) {
        this.toggleOurServices(false)
      }
    },
    hideHelpCentreDesktop() {
      const screenWidth = getScreenWidth()
      if (screenWidth && screenWidth >= 768) {
        this.toggleHelpCentre(false)
      }
    },
    track(property: string, label: string) {
      this.$snowplow.trackNavMenuClickEvent(property, label)
    }
  }
})
