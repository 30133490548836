// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.diy-breadcrumbs {\n    position: relative;\n    z-index: 10\n}\n@media (min-width: 992px) {\n.diy-breadcrumbs {\n        margin-bottom: -48px\n}\n}\n.diy-breadcrumbs > .content {\n    padding-top: 16px;\n    padding-bottom: 0\n}\n.breadCrumb_dNNGj .av-paragraph {\n    margin: 0;\n    word-wrap: break-word;\n    font-size: 0.875rem;\n    line-height: 1.2\n}\n.breadCrumb_dNNGj .breadcrumbLink_5BTaz {\n    --tw-text-opacity: 1;\n    color: rgba(197, 197, 197, 1);\n    color: rgba(197, 197, 197, var(--tw-text-opacity))\n}\n\n/* current page title */\n.breadCrumb_dNNGj .breadcrumbLink_5BTaz a {\n    --tw-text-opacity: 1;\n    color: rgba(164, 164, 164, 1);\n    color: rgba(164, 164, 164, var(--tw-text-opacity))\n}\n.breadCrumb_dNNGj .breadcrumbIcon_pDMZN {\n    padding-left: 4px;\n    padding-right: 4px\n}\n.breadCrumb_dNNGj .breadcrumbIcon_pDMZN svg {\n    fill: #a4a4a4\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadCrumb": "breadCrumb_dNNGj",
	"breadcrumbLink": "breadcrumbLink_5BTaz",
	"breadcrumbIcon": "breadcrumbIcon_pDMZN"
};
module.exports = ___CSS_LOADER_EXPORT___;
